import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ReactMarkdown from "react-markdown";
import { config } from "../config";
import { AppNavbar } from "../general/AppNavbar";
import Changelog from '../texts/CHANGELOG.link.md';

function MarkdownRender({mdFileReference = Changelog, children}) {

    const [text, setText] = useState('');
    const history = useHistory();
    const { t } = useTranslation();

    useEffect(() => {
        document.title = config.TITLE_BASE + t('title_changelog');
    }, []);

    useEffect(() => {
        if(config.IS_BETA) console.log('Trying to read MD file');
        fetch(mdFileReference)
            .then(md => md.text())
            .then(mdText => setText(mdText))
            .catch(err => {
                if(config.IS_BETA) console.error('Error loading md file', err)
            });
    }, [mdFileReference]);

    const goBack = () => {
        history.goBack();
    }

    return (
        <div className="w-100">
            <AppNavbar showContextSelector={false} showProfile={false}/>
            <div className="container mt-5 py-5">
                <div className="d-flex justify-content-between">
                    <button onClick={goBack} className="btn text-vitag d-flex align-items-center">
                        <span className="material-icons-round mr-2">arrow_back</span>
                        {t('changelog_return')}
                    </button>
                    { children }
                </div>
                <ReactMarkdown children={text} className='mt-4'/>
            </div>
        </div> );
}

export { MarkdownRender };
