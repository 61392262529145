import React, { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { GeneralContext } from "../contexts/general-context";
import { defaultNoProfilePicture } from "../utils/ImageUtils";

function ProfileIndicator({ useDarkTheme = true }) {

    const { profile, isProUser } = useContext(GeneralContext);
    const { t } = useTranslation();
    const [avatarImage, setAvatarImage] = useState('');

    useEffect(() => {
        setAvatarImage(profile?.avatarImage);
    }, [profile]);

    const putFallbackImage = () => {
        setAvatarImage(defaultNoProfilePicture);
    }

    return (
        <div className="d-flex gap-3 align-items-center my-auto">
            <Link to="/me" title={t('general_gotoprofile')}
                className={'text-right ' + (useDarkTheme ? 'text-dark' : 'text-light')}>
                {profile?.fullName || profile?.nickname}
            </Link>
            <Link to="/me" className="position-relative d-inline-block">
                <img src={avatarImage} onError={putFallbackImage} 
                    style={{width:'44px', height:'44px', objectFit:'cover', borderWidth:'1px', lineHeight:'2.5em', backgroundColor:'#ffffff'}}
                    className="profile-image text-center"/>
                    {isProUser && <span className="badge-icon">PRO</span>}
            </Link>
        </div>
    );
}

export { ProfileIndicator };
