// create modal component for check backend status and display it

import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import packageJson from '../package.alias.json';
import { Modal } from 'react-bootstrap';
import { getCoreVersion, getIAVersion } from '../services/CoreService';

function ModalStatus() {

    const { t } = useTranslation();
    const [showModal, setShowModal] = useState(false);
    const [coreStatus, setCoreStatus] = useState();
    const [iaStatus, setIaStatus] = useState();

    const closeThisModal = () => {
        setShowModal(false);
    }

    const initModal = () => {
        setCoreStatus({loading:true});
        setIaStatus({loading:true});
        getCoreVersion()
            .then(resp => setCoreStatus({online:true, loading:false, ...resp.data}))
            .catch(_ => setCoreStatus({error:true, loading:false}));
        getIAVersion()
            .then(resp => setIaStatus({online:true, loading:false, ...resp.data}))
            .catch(_ => setIaStatus({error:true, loading:false}));
}

    return <>
        <button onClick={() => setShowModal(true)} className="btn btn-sm btn-primary bg-vitag">
            {t('modal_status_btn_open_modal')}
        </button>
        <Modal show={showModal} onShow={initModal} onHide={closeThisModal} size="lg">
            <Modal.Header>
                <button onClick={closeThisModal}
                    title={t('general_close')}
                    className="btn btn-sm text-secondary">
                    <span className="material-icons-round">close</span>
                </button>
                <Modal.Title>{t('modal_status_title')}</Modal.Title>
                <span className="btn-placeholder"></span>
            </Modal.Header>
            <Modal.Body className='px-5 pb-4'>
                <table className="table table-borderless">
                    <thead>
                        <tr>
                            <th>{t('modal_status_header_service')}</th>
                            <th>{t('modal_status_header_status')}</th>
                            <th>{t('modal_status_header_version')}</th>
                            <th>{t('modal_status_header_last_update')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{t('modal_status_backend_core')}</td>
                            { coreStatus?.error && <td className="d-flex gap-2 text-danger">
                                <span className="material-icons-round">error</span>
                                <span>Inestable</span>
                            </td> }
                            <td className='d-flex gap-2'>
                                { coreStatus?.online && <>
                                    <span className="material-icons-round text-success">check_circle</span>
                                    <span className='text-success'>Ok</span>
                                </> }
                            </td>
                            <td>v{ coreStatus?.version || '--' }</td>
                            <td>{ coreStatus?.lastUpdate || '--' }</td>
                        </tr>
                        <tr>
                            <td>{t('modal_status_complement_ia')}</td>
                            { iaStatus?.error && <td className='d-flex gap-2 text-danger'>
                                <span className="material-icons-round">error</span>
                                <span>Inestable</span>
                            </td> }
                            <td className='d-flex gap-2'>
                            { iaStatus?.online && <>
                                <span className="material-icons-round text-success">check_circle</span>
                                <span className='text-success'>Ok</span>
                            </> }
                            </td>
                            <td>{ iaStatus?.version || '--' }</td>
                            <td>{ iaStatus?.lastUpdate || '--' }</td>
                        </tr>
                        <tr>
                            <td>{t('modal_status_frontend')}</td>
                            <td className='d-flex gap-2'>
                                <span className="material-icons-round text-success">check_circle</span>
                                <span className='text-success'>Ok</span>
                            </td>
                            <td>v{ packageJson.version }</td>
                            <td>{ packageJson.lastUpdate }</td>
                        </tr>
                    </tbody>
                </table>
            </Modal.Body>
        </Modal>
    </>;
}        

export { ModalStatus };